import React, { useRef } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { Checkbox, DateInput, Section, SelectInput, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType, getCropOptionsData } from "~/reports/data/selectors";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";

import { useAppSelector } from "~/store/hooks";
import { IOption, ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import { getEventGeneralGuidToEventSummaryMap } from "~/recs-events/events/selectors";

type SkyPlantingSummaryProps = {
    selectedCrop: ICrop | null;
};

function SkyPlantingSummary({
    intl: { formatMessage },
    onInputChange,
    selectedCrop,
}: ReportOptionsComponentProps & SkyPlantingSummaryProps) {
    const reportData = useAppSelector(getReportDataByReportType(SkyPlantingSummary.reportName));
    const crops = useAppSelector(getCropOptionsData);
    const agEventSummaryMap = useAppSelector(getEventGeneralGuidToEventSummaryMap);
    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );

    const selectedEventGuidList = useAppSelector(getSelectedEventGuidSet);
    const seasonsToDisplay = useRef<string[]>();

    React.useEffect(() => {
        onInputChange(model.PROPS_SELECTED_EVENT_GUID_LIST, [...selectedEventGuidList]);

        if (selectedEventGuidList.size > 0) {
            const seasonGuids = [];

            for (const eventGeneralGuid of selectedEventGuidList) {
                const event = agEventSummaryMap.get(eventGeneralGuid);
                if (event.agEventTypeName === "Planting") {
                    seasonGuids.push(event.croppingSeasonGuid);
                }
            }
            const seasonsText = [];
            for (const seasonGuid of seasonGuids) {
                const selectedSeason = seasons.find((season) => season.value === seasonGuid);
                if (seasonsText.indexOf(selectedSeason.label) === -1) {
                    seasonsText.push(selectedSeason.label);
                }
            }
            seasonGuids.sort();
            seasonsToDisplay.current = seasonsText;
        } else {
            seasonsToDisplay.current = [];
        }
    }, [selectedEventGuidList.size]);

    const varietyHybridOptions: IOption[] = [
        {
            label: formatMessage(messages.varietyHybrid),
            value: "variety",
        },
        {
            label: formatMessage(messages.monitorVariety),
            value: "monitor",
        },
        {
            label: formatMessage(messages.aliasVarietyHybrid),
            value: "alias",
        },
    ];
    const optionsDisabled = selectedEventGuidList.size > 0;
    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] ||
        reportData[model.PROPS_END_DATE] ||
        selectedEventGuidList.size > 0;

    return (
        <Section className="report-options">
            <SubSection>
                <CroppingSeason
                    disabled={croppingSeasonDisabled}
                    required={!croppingSeasonDisabled}
                    reportType={SkyPlantingSummary.reportName}
                    valuesToDisplay={seasonsToDisplay.current}
                />
                <SelectInput<ICrop>
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.crop)}
                    options={optionsDisabled ? [] : crops}
                    onChange={(value) => {
                        onInputChange(model.PROPS_CROP_GUID, value ? value.cropGuid : null);
                    }}
                    value={optionsDisabled ? null : (selectedCrop as ICrop)}
                />
            </SubSection>
            <SubSection>
                <DateInput
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.startDate)}
                    onChange={(value) =>
                        onInputChange(model.PROPS_START_DATE, value ? value : null)
                    }
                    timeFormat={false}
                    value={optionsDisabled ? null : reportData[model.PROPS_START_DATE]}
                />
                <DateInput
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.endDate)}
                    onChange={(value) => onInputChange(model.PROPS_END_DATE, value ? value : null)}
                    timeFormat={false}
                    value={optionsDisabled ? null : reportData[model.PROPS_END_DATE]}
                />
            </SubSection>
            <SubSection className="two-report-options">
                <SelectInput
                    required
                    clearable={false}
                    containerClassNames={["display-options-list"]}
                    placeholderText={formatMessage(messages.varietyHybridType)}
                    options={varietyHybridOptions}
                    onChange={(value) => {
                        onInputChange(model.PROPS_VARIETY_HYBRID_TYPE, value);
                    }}
                    value={reportData[model.PROPS_VARIETY_HYBRID_TYPE]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.displayTraits)}
                    onChange={(e, value) => onInputChange(model.PROPS_DISPLAY_TRAITS, value)}
                    value={reportData[model.PROPS_DISPLAY_TRAITS]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.displayFieldAcres)}
                    onChange={(e, value) => onInputChange(model.PROPS_DISPLAY_FIELD_ACRES, value)}
                    value={reportData[model.PROPS_DISPLAY_FIELD_ACRES]}
                />
                <Checkbox
                    disabled={false}
                    label={formatMessage(messages.summary)}
                    onChange={(e, value) => onInputChange(model.PROPS_PLANTING_SUMMARY, value)}
                    value={reportData[model.PROPS_PLANTING_SUMMARY]}
                />
            </SubSection>
        </Section>
    );
}

SkyPlantingSummary.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
    selectedCrop: null,
};

SkyPlantingSummary.defaultReportOptions = {
    [model.PROPS_CROP_GUID]: null,
    [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
    [model.PROPS_VARIETY_HYBRID_TYPE]: "variety",
    [model.PROPS_START_DATE]: null,
    [model.PROPS_END_DATE]: null,
    [model.PROPS_CROPPING_SEASON_GUID_LIST]: [],
    [model.PROPS_DISPLAY_TRAITS]: false,
    [model.PROPS_DISPLAY_FIELD_ACRES]: true,
    [model.PROPS_PLANTING_SUMMARY]: true,
};

SkyPlantingSummary.reportPreferences = [
    model.PROPS_DISPLAY_TRAITS,
    model.PROPS_DISPLAY_FIELD_ACRES,
    model.PROPS_PLANTING_SUMMARY,
    model.PROPS_VARIETY_HYBRID_TYPE,
];

SkyPlantingSummary.reportName = ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_SUMMARY;

export default injectIntl(SkyPlantingSummary) as ReportOptionsComponent<
    ReportOptionsComponentProps & SkyPlantingSummaryProps
>;
